import { Injectable } from '@angular/core';
import { BypassHttpService } from './bypass-http.service';
import { ENDPOINTS, ISignInPayload, ISignInResponse } from '../../api/api';
import { catchError, finalize, Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { IsArray, IsNumber, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { TransformValidation } from '../util/transform-validation';
import { decodeJwtPayload } from '../util/util';
import { AppConfigService } from '../../app-config.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';

export interface IJwtToken {
  iat: number;
  exp?: number;
}

export interface IUser extends IJwtToken {
  email: string;
  roles: Array<string>;
}


export class User implements IUser {
  @IsString()
  @Type(() => String)
  email!: string;
  @IsNumber()
  @Type(() => Number)
  iat!: number;
  @IsArray()
  @Type(() => String)
  roles!: Array<string>;

  constructor() {
  }

  static create(token: string): IUser | null {
    try {
      const user = TransformValidation.performSync<User>(User, decodeJwtPayload(token), {notThrowError: true});
      return user;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: IUser | null = null;
  private _isSigningIn: boolean = false;
  private _authState: ReplaySubject<IUser | null> = new ReplaySubject(1);

  constructor(
    private socialAuthService: SocialAuthService,
    private bypassHttp: BypassHttpService,
    private appConfig: AppConfigService,
    private router: Router
  ) {
    const token = localStorage.getItem('token');
    this.setUser(token);
    this.socialAuthService.authState.subscribe((socialUser) => {
      const navigateTo = '';
      this.bypassHttp.request<ISignInResponse, ISignInPayload>('post', ENDPOINTS.signIn, {
        body: {
          token: socialUser.idToken
        }
      }).pipe(
        catchError((error) => {
          // reject(error);
          throw error;
        }),
        finalize(() => this._isSigningIn = false)
      ).subscribe(async (response) => {
        this.setUser(response.token);
        try {
          await this.appConfig.init(true);
          localStorage.setItem('refreshToken', response.refreshToken);
          // resolve(this.user!);
          if (navigateTo != null) {
            this.router.navigate([navigateTo]);
          }
        } catch (e) {
          this.setUser(null);
          this._isSigningIn = false;
          // reject(e);
        }
      });
    })
  }

  setUser(token: string | null): void {
    if (!!token) {
      this._user = User.create(token);
      this._authState.next(this._user);
      if (!!this._user) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    } else {
      this._user = null;
      this._authState.next(null);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
  }

  signOut(): void {
    this.setUser(null);
    this.router.navigate(['login']);
  }

  isSignedIn(): boolean {
    return !!this._user;
  }

  get isSigningIn(): boolean {
    return this._isSigningIn;
  }

  get authState(): Observable<IUser | null> {
    return this._authState.asObservable();
  }

  get user(): IUser | null {
    return this._user;
  }
}
