import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../common/services/auth.service';

@Component({
  selector: 'app-login-page',
  template: `
    <div class="grid mt-6">
      <div class="col-4"></div>
      <div #panel class="col-4">
        <p-messages *ngIf="auth.isSignedIn()" severity="info">
          <ng-template pTemplate>
            <div class="w-full flex align-items-center justify-content-between">
              <span class="flex align-items-center">
                <i class="pi pi-info-circle mr-3 text-2xl"></i>
                <span>Your signed in as</span><span class="ml-2 font-medium">{{auth.user?.email}}</span>
              </span>
              <p-button label="Sign Out" icon="pi pi-sign-out" styleClass="ml-2 p-button-sm"
                        (click)="auth.signOut()"></p-button>
            </div>
          </ng-template>
        </p-messages>

        <div *ngIf="!auth.isSignedIn()" class="mt-3 w-full p-card p-6">
          <h3 class="mt-3 mb-4 text-center">Login to TaaSinator</h3>
          <div class="flex align-items-center justify-content-center">
            <asl-google-signin-button #asl type="icon" size="medium"></asl-google-signin-button>
            <span class="ml-2">Sign in with Google</span>
          </div>
        </div>
      </div>
    </div>
  `
})
export class LoginPageComponent {
  constructor(public auth: AuthService) {
  }
}
