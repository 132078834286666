export const ENDPOINTS = {
  signIn: '/api/auth/google',
  refreshToken: '/api/auth/refresh-token',
  currentUserProfile: '/api/users/me',
};

export interface ISignInPayload {
  token: string;
}

export interface ISignInResponse {
  token: string;
  refreshToken: string;
}

export interface IRefreshTokenPayload {
  refreshToken: string;
}

export type INewUserProfileRecord = {
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
};

export type IUserProfileRecord = INewUserProfileRecord & {
  createdAt: string;
};

